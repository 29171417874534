<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: "App",
	};
</script>

<style>
	body,
	html,
	p,
	ul,
	li {
		padding: 0;
		margin: 0;
		list-style: none;
		-moz-user-select: none !important;
		-khtml-user-select: none !important;
		user-select: none !important;
	}

	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.align-start {
		align-items: flex-start;
	}

	.align-end {
		align-items: flex-end;
	}

	.flex-1 {
		flex: 1;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-between {
		justify-content: space-between;
	}

	.flex-column {
		flex-direction: column;
	}

	.flex-row {
		flex-direction: row;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

</style>