import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router/index";
import * as echarts from 'echarts';//引入echarts
import './utils/rem.js'
import 'amfe-flexible/index.js'



import axios from "axios";

Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  el: '#app',
  render: h => h(App),
}).$mount('#app')
